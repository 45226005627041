import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Account = lazy(() => import("../../pages/account/Account"));
const Address = lazy(() => import("../../pages/account/Address"));
const AddAddress = lazy(() => import("../../pages/account/AddAddress"));
const Security = lazy(() => import("../../pages/account/Security"));
const Notification = lazy(() => import("../../pages/account/Notification"));
const Profile = lazy(() => import("../../pages/account/Profile"));

function AccountRoute() {
  return (
    <main className="flex justify-center w-full pt-20 sm:pb-5 pb-0 sm:pt-28 sm:px-4">
      <div className="flex gap-7 w-full justify-center container sm:px-3">
        <Account />
        <Routes>
          <Route path="/profile" element={<Profile />} />
          <Route path="/security" element={<Security />} />
          <Route path="/address" element={<Address />} />
          <Route path="/add-address" element={<AddAddress />} />
          <Route path="/edit-address/:id" element={<AddAddress />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/pusat-bantuan" element={<Security />} />
          {!isMobile && <Route path="/" element={<Navigate replace to="/account/profile" />} />}
        </Routes>
      </div>
    </main>
  );
}

export default AccountRoute;
