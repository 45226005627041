import { isEmpty } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { CartService } from "../../apis/cart";

const useMutateAddToCart = (enqueueSnackbar) => {
  const queryClient = useQueryClient();
  return useMutation((values) => CartService.addToCart(values), {
    onMutate: () => {
      console.log("mutate add to cart");
    },
    onSettled: async (data) => {
      if (data.meta.success) {
        queryClient.invalidateQueries("cart");
        queryClient.invalidateQueries("cart-counter");
        enqueueSnackbar("Produk berhasil ditambahkan kedalam keranjang!", {
          variant: "success",
          autoHideDuration: 1500,
        });
      }
    },
  });
};

const useFetchUserCart = () =>
  useQuery(["cart"], () => CartService.getUserCart(), {
    refetchOnWindowFocus: false,
  });

const useMutateUpdateCart = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => CartService.updateCart(values), {
    onMutate: () => {
      console.log("mutate update cart");
    },
    onSettled: async (data) => {
      if (data.meta.success) {
        queryClient.invalidateQueries("cart");
      }
    },
  });
};

const useMutateDeleteCart = (enqueueSnackbar) => {
  const queryClient = useQueryClient();
  return useMutation((cart_id) => CartService.deleteCart(cart_id), {
    onMutate: () => {
      console.log("mutate delete cart");
    },
    onSettled: async () => {
      queryClient.invalidateQueries("cart");
      queryClient.invalidateQueries("cart-counter");
      enqueueSnackbar("Produk telah dihapus dari keranjang!", {
        variant: "success",
        autoHideDuration: 1500,
      });
    },
    onError: () => {
      console.log("error mutating delete cart");
      enqueueSnackbar("Sorry, something went wrong", {
        variant: "error",
        autoHideDuration: 1500,
      });
    },
  });
};

const useFetchCartCounter = ({ access_token }) =>
  useQuery(["cart-counter"], () => CartService.getCartCounter(), {
    refetchOnWindowFocus: false,
    enabled: !!access_token,
  });

const useMutateCheckoutCart = () => {
  const navigate = useNavigate();
  return useMutation((payload) => CartService.checkoutCart({ payload }), {
    onMutate: () => {
      console.log("mutate checkout cart");
    },
    onSettled: async (data) => {
      if (!data.data.simulated && data.meta.success && !isEmpty(data.data.description.webview_url)) {
        window.location.href = data.data.description.webview_url;
      } else if (!data.data.simulated && data.meta.success) {
        navigate(`/cart/receipt/${data?.data?.description?.invoice_payment_id}`);
      }
    },
  });
};

const useMutateOutletCourier = () =>
  useMutation((payload) => CartService.getOutletCouriers({ payload }), {
    onMutate: () => {
      console.log("mutate outlet courier");
    },
  });

export {
  useMutateAddToCart,
  useFetchUserCart,
  useMutateUpdateCart,
  useMutateDeleteCart,
  useFetchCartCounter,
  useMutateCheckoutCart,
  useMutateOutletCourier,
};
