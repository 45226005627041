import { lazy, Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import ProductRoute from "./product";
import AccountRoute from "./account";
import CategoryRoute from "./category";
import CartRoute from "./cart";
import TransactionRoute from "./transaction";
import OutletRoute from "./outlet";
import RequireAuth from "../helpers/RequireAuth";
import { ALoading } from "../components/atoms/ALoading";

const Home = lazy(() => import("../pages/home/Home"));

function MainRoute() {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center pt-28">
          <ALoading />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/home" element={<Home />} />
          <Route path="/category/*" element={<CategoryRoute />} />
          <Route path="/product/*" element={<ProductRoute />} />
          <Route path="/outlet/*" element={<OutletRoute />} />

          <Route element={<RequireAuth />}>
            <Route path="/account/*" element={<AccountRoute />} />
            <Route path="/cart/*" element={<CartRoute />} />
            <Route path="/transaction/*" element={<TransactionRoute />} />
          </Route>

          <Route path="/" element={<Navigate replace to="/home" />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default MainRoute;
