import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useFormik } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import ADialogBox from "../../atoms/ADialogBox";
import ATextField from "../../atoms/ATextField";
import useAuth from "../../../helpers/useAuth";
import { AuthService } from "../../../apis/auth";
import localStorageService from "../../../utils/localStorageService";
import ADatePicker from "../../atoms/ADatePicker";

function ORegisterDialog() {
  const { auth, setAuth } = useAuth();
  const { openRegisterDialog, payload } = auth;

  const setOpenRegisterDialog = () => {
    setAuth({ ...auth, openRegisterDialog: !openRegisterDialog });
  };

  const {
    handleChange, handleSubmit, values, errors, touched, setValues,
  } = useFormik({
    initialValues: {
      name: "",
      country_code: payload.country_code,
      phone_number: payload.phone_number,
      email: "",
      gender: "",
      birth_date: "",
      otp: payload.otp,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Format Email Salah").required("Email tidak boleh kosong."),
      country_code: Yup.string().required("Kode negara tidak boleh kosong."),
      name: Yup.string().required("Nama tidak boleh kosong."),
      birth_date: Yup.string().required("Tanggal lahir tidak boleh kosong."),
      gender: Yup.string().required("Jenis kelamin tidak boleh kosong."),
    }),
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  const mutation = useMutation((values) => AuthService.register(values), {
    onMutate: () => {
      console.log("mutate register");
    },
    onSettled: async (data) => {
      if (data.meta.success) {
        const token = data?.data?.access_token;
        const name = data?.data?.nama_lengkap;
        localStorageService.setToken(token);
        localStorageService.setProfileName(name);
        setAuth({
          ...auth,
          accessToken: token,
          openVerifOtpDialog: false,
          openLoginDialog: false,
          openRegisterDialog: false,
        });
      }
    },
  });

  return (
    <ADialogBox
      content={{
        title: "Profil Dasar",
        body: (
          <form onSubmit={handleSubmit} className="my-2">
            <div className={`h-10 ${errors.name ? "mb-7" : "mb-5"}`}>
              <ATextField
                type="text"
                placeholder="Nama"
                label="Nama"
                name="name"
                value={values.name}
                handleChange={handleChange}
                error={!!(touched.name && errors.name)}
                helperText={errors.name}
              />
            </div>
            <div className={`h-10 mt-5 ${errors.email ? "mb-7" : "mb-5"}`}>
              <ATextField
                type="text"
                placeholder="Email"
                label="Email"
                name="email"
                value={values.email}
                handleChange={handleChange}
                error={!!(touched.email && errors.email)}
                helperText={errors.email}
              />
            </div>

            <RadioGroup row className="flex w-full">
              <div className="flex gap-5 w-full">
                <div
                  className={`w-1/2 flex rounded-md border items-center ${
                    touched.gender && errors.gender && "border-error"
                  }`}
                >
                  <FormControlLabel
                    onChange={() => setValues({ ...values, gender: "L" })}
                    sx={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginLeft: "0.5px",
                    }}
                    value="pria"
                    control={<Radio size="small" />}
                    label={<p className={`text-sm ${touched.gender && errors.gender && "text-error"}`}>Pria</p>}
                  />
                </div>
                <div
                  className={`w-1/2 flex rounded-md border items-center ${
                    touched.gender && errors.gender && "border-error"
                  }`}
                >
                  <FormControlLabel
                    onChange={() => setValues({ ...values, gender: "P" })}
                    sx={{
                      display: "flex",
                      width: "100%",
                      paddingLeft: "0.6rem",
                    }}
                    value="wanita"
                    control={<Radio size="small" />}
                    label={<p className={`text-sm ${touched.gender && errors.gender && "text-error"}`}>Wanita</p>}
                  />
                </div>
              </div>
              {errors.gender && touched.gender && (
              <p className="text-error text-xs mt-0.5">
                {" "}
                {errors.gender}
              </p>
              )}
            </RadioGroup>

            <div className={`w-full ${errors.birth_date ? "mt-2" : "mt-4"}`}>
              <ADatePicker
                label="Tanggal Lahir"
                value={values.birth_date}
                onChange={(newValue) => {
                  setValues({ ...values, birth_date: newValue });
                }}
              />
              {errors.birth_date && touched.birth_date && (
                <p className="text-error text-xs mt-0.5">{errors.birth_date}</p>
              )}
            </div>

            <div className="mt-5">
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                style={{ width: "100%" }}
                loading={mutation.isLoading}
              >
                Kirim
              </LoadingButton>
            </div>
          </form>
        ),
      }}
      isOpen={openRegisterDialog}
      setIsOpen={setOpenRegisterDialog}
    />
  );
}

export default ORegisterDialog;
