import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Product = lazy(() => import("../../pages/product/Product"));
const ProductDetail = lazy(() => import("../../pages/product/ProductDetail"));

function ProductRoute() {
  return (
    <Routes>
      <Route path="/" element={<Product />} />
      <Route path="/:id" element={<ProductDetail />} />
    </Routes>
  );
}

export default ProductRoute;
