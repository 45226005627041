import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Cart = lazy(() => import("../../pages/cart/Cart"));
const Checkout = lazy(() => import("../../pages/cart/Checkout"));
const Receipt = lazy(() => import("../../pages/cart/Receipt"));
const AfterPayment = lazy(() => import("../../pages/cart/AfterPayment"));

function CartRoute() {
  return (
    <Routes>
      <Route path="/" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/receipt/:id" element={<Receipt />} />
      <Route path="/payment-successfully" element={<AfterPayment />} />
    </Routes>
  );
}

export default CartRoute;
