import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Outlet = lazy(() => import("../../pages/outlet/Outlet"));
const OutletDetail = lazy(() => import("../../pages/outlet/OutletDetail"));

function OutletRoute() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />} />
      <Route path="/:id" element={<OutletDetail />} />
    </Routes>
  );
}

export default OutletRoute;
