import { useInfiniteQuery, useQuery } from "react-query";
import { ProductService } from "../../apis/product";

const useFetchProducts = ({
  category_id,
  size,
  keyword,
  enabled = true,
  outlet_id,
  final_price__gte,
  final_price__lte,
  province_code,
  sort,
}) =>
  useInfiniteQuery(
    ["products", category_id, keyword, outlet_id, province_code, sort],
    ({ pageParam = 1 }) =>
      ProductService.getProducts({
        pageParam,
        category_id,
        size,
        keyword,
        outlet_id,
        final_price__gte,
        final_price__lte,
        province_code,
        sort,
      }),
    {
      getNextPageParam: (pages) => {
        if (pages.nextPage <= pages?.results?.page_info?.last_page) return pages.nextPage;
        return undefined;
      },
      enabled,
    },
  );

const useFetchSearch = ({ size, keyword, enabled = true }) =>
  useQuery(["search", keyword], () => ProductService.getSearch({ size, keyword }), {
    refetchOnWindowFocus: false,
    enabled,
  });

const useFetchProduct = (product_id) =>
  useQuery([`product=${product_id}`, product_id], () => ProductService.getProduct(product_id), {
    refetchOnWindowFocus: false,
  });

const useFetchProductReviews = ({ product_id, size, rating, enabled = true }) =>
  useInfiniteQuery(
    [`product-reviews-${product_id}`, product_id, rating],
    ({ pageParam = 1 }) => ProductService.getReviewByProduct({ pageParam, product_id, size, rating }),
    {
      getNextPageParam: (pages) => {
        if (pages.nextPage <= pages?.data?.page_info?.last_page) return pages.nextPage;
        return undefined;
      },
      enabled,
    },
  );

const useFetchProductReviewSummaries = ({ product_id }) =>
  useQuery([`product-reviews-summaries-${product_id}`, product_id], () =>
    ProductService.getReviewSummaries({ product_id }),
  );

const useFetchProductRecommendations = () =>
  useQuery(["product-recommendations"], () => ProductService.getProductRecommendations(), {
    refetchOnWindowFocus: false,
  });

const useFetchProductPopulars = () =>
  useQuery(["product-populars}"], () => ProductService.getProductPopulars(), {
    refetchOnWindowFocus: false,
  });

export {
  useFetchProducts,
  useFetchProduct,
  useFetchProductReviews,
  useFetchProductReviewSummaries,
  useFetchSearch,
  useFetchProductRecommendations,
  useFetchProductPopulars,
};
