import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";
import moment from "moment";
import useAuth from "./useAuth";

export const checkToken = (token) => {
  if (!token) return false;
  const decoded = jwtDecode(token);
  if (decoded.exp * 1000 < moment().valueOf()) return false;
  return true;
};

function RequireAuth() {
  const { auth } = useAuth();
  const location = useLocation();

  return checkToken(auth.accessToken) ? <Outlet /> : <Navigate to="/home" state={{ from: location }} replace />;
}

export default RequireAuth;
