export const formatCurrency = (value) => value.toLocaleString("id-ID", {
  style: "currency",
  currency: "IDR",
});

export const subsPhoneNumber = (phone_number) => {
  const sub_phone_number = Number(phone_number[0]) === 0
    ? phone_number.substring(1)
    : Number(phone_number.substring(0, 2)) === 62
      ? phone_number.substring(2)
      : phone_number;
  return sub_phone_number;
};

export const getDiscountPercentage = (normal_price, discount_price) => {
  const difference = normal_price - discount_price;

  const percentage = (difference / normal_price) * 100;
  return percentage.toFixed();
};

export const generateDummyArr = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: k,
    url: null,
    file: null,
  }));
