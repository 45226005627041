import { useEffect, useState } from "react";
import { BsCart2 } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineHistory } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, Divider, Menu, MenuItem, MenuList } from "@mui/material";
import { isMobile } from "react-device-detect";
import { debounce } from "lodash";
import { AiOutlineLogin } from "react-icons/ai";
import ASearchbar from "../atoms/ASearchbar";
import { line } from "../../assets/icons";
import useAuth from "../../helpers/useAuth";
import localStorageService from "../../utils/localStorageService";
import { useFetchSearch } from "../../hooks/product";
import { useFetchCartCounter } from "../../hooks/cart";
import { logo } from "../../assets/images";

function MMenubar() {
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [OpenSearchBar, setOpenSearchBar] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { auth, setAuth } = useAuth();

  const name = localStorageService.getProfileName();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (link) => {
    navigate(link);
    handleClose();
  };

  const handleLogout = () => {
    localStorageService.clearLocalStorage();
    setAuth({ ...auth, accessToken: "" });
  };

  const setOpenLoginDialog = () => {
    setAuth({ ...auth, openLoginDialog: true });
  };
  const { data } = useFetchSearch({
    keyword: searchInput,
    size: 5,
    enabled: !(searchInput.length <= 0),
  });

  const { data: dataCounter, refetch } = useFetchCartCounter({ access_token: auth.accessToken });

  useEffect(() => {
    if (auth.accessToken) {
      refetch();
    }
  }, [auth]);

  return (
    <nav className="fixed z-20 m:h-[5.5rem] h-20 w-full shadow-md flex justify-center bg-secondary">
      <nav className="container px-3">
        <section className="flex sm:gap-7 gap-3 items-center h-full w-full container">
          <div className="w-1/5 sm:flex hidden -ml-1">
            <img src={logo} alt="logo" className="cursor-pointer" data-srcset="https://danmogot.com/assets/storage/logo/logo-putih.png 320w" onClick={() => navigateTo("/home")} />
          </div>
          {pathname !== "/home" && (
          <div className="sm:w-[5%] w-[3%] h-fit sm:hidden flex">
            <img src={line} alt="logo" className="cursor-pointer sm:w-full w-8" onClick={() => navigateTo(-1)} />
          </div>
          )}

          <div className="sm:w-4/5 w-full flex justify-center items-center sm:gap-7">
            <div className="w-full h-10 flex justify-between sm:gap-5 gap-3">
              <div className="w-full">
                <ASearchbar
                  onChange={debounce((e) => {
                    setSearchInput(e.target.value);
                  }, 200)}
                  onParentFocus={() => setOpenSearchBar(true)}
                  onParentBlur={(event) => {
                    if (!event.currentTarget.contains(event.relatedTarget)) {
                      setOpenSearchBar(false);
                    }
                  }}
                  placeholder="Cari barang? Cari disini yuk"
                >
                  {OpenSearchBar && data && (
                  <div className="bg-white w-full px-2 mt-2 shadow-sm rounded">
                    {data?.products?.length === 0 && data?.outlets?.length === 0 && (
                      <p className="text-xs text-gray ml-2 py-4">No result</p>
                    )}
                    {data.products.length > 0 && (
                      <>
                        <p className="text-sm ml-2 pt-3 mb-2">Products</p>
                        <Divider />
                        <MenuList dense>
                          {data.products.map((product, index) => (
                            <MenuItem
                              onClick={() => {
                                navigateTo(`/product?search=${product.name}`);
                                setOpenSearchBar(false);
                              }}
                              sx={{ color: "gray" }}
                              key={index}
                            >
                              {product.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                    {data.outlets.length > 0 && (
                      <>
                        <p className="text-sm ml-2 mb-2 pt-2">Outlets</p>
                        <Divider />
                        <MenuList dense>
                          {data.outlets.map((outlet, index) => (
                            <MenuItem
                              onClick={() => {
                                navigateTo(`/outlet/${outlet.id}`);
                                setOpenSearchBar(false);
                              }}
                              sx={{ color: "gray" }}
                              key={index}
                            >
                              {outlet.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </div>
                  )}
                </ASearchbar>
              </div>
              {auth.accessToken && (
              <div className="flex justify-evenly gap-4 sm:gap-6 items-center">
                <div className="cursor-pointer sm:w-full w-8" onClick={() => navigateTo("/cart")}>
                  <Badge badgeContent={dataCounter?.data} color="primary" max={99}>
                    <BsCart2 size={25} className="fill-primary" />
                  </Badge>
                </div>
                {/* <div className="sm:flex mt-1 hidden cursor-pointer">
                  <Badge badgeContent={1000} color="primary" max={99}>
                    <IoIosNotificationsOutline
                      size={30}
                      className="fill-primary"
                    />
                  </Badge>
                </div> */}
                <div className="cursor-pointer w-8 sm:hidden" onClick={() => navigateTo("/account")}>
                  <Avatar sx={{ width: "30px", height: "30px" }} />
                </div>
              </div>
              )}
            </div>

            {auth.accessToken ? (
              <div className="sm:flex hidden p-2 sm:w-[30%] bg-white rounded-full h-11 shadow-sm">
                <div className="flex gap-2 items-center w-full">
                  <Avatar sx={{ width: "27px", height: "27px" }} />
                  <div className="w-4/5">
                    <p onClick={handleClick} className="truncate cursor-pointer text-sm">
                      {name || "User"}
                    </p>
                  </div>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.7,
                      ml: 1,
                      width: "14%",
                      "&:before": {
                        content: "\"\"",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 12,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    className="flex gap-2"
                    onClick={() => (isMobile ? navigateTo("/account") : navigateTo("/account/profile"))}
                  >
                    <FaRegUser /> Akun
                  </MenuItem>
                  <MenuItem className="flex gap-2" onClick={() => navigateTo("/transaction")}>
                    <MdOutlineHistory /> Transaksi
                  </MenuItem>
                  <MenuItem className="flex gap-2" onClick={handleLogout}>
                    <FiLogOut /> Keluar
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <aside className="flex items-center sm:w-[30%] h-10 py-0.5">
                <div className="sm:flex hidden w-full">
                  <Button
                    onClick={setOpenLoginDialog}
                    variant="contained"
                    sx={{ flex: 1, width: "100%", height: "100%" }}
                  >
                    Masuk / Daftar
                  </Button>
                </div>
                <div
                  onClick={setOpenLoginDialog}
                  className="sm:hidden flex items-center flex-1 ml-4 bg-white rounded-full p-3"
                >
                  <AiOutlineLogin />
                </div>
              </aside>
            )}
          </div>
        </section>
      </nav>
    </nav>
  );
}

export default MMenubar;
