import { useFormik } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../helpers/useAuth";
import ADialogBox from "../../atoms/ADialogBox";
import ATextField from "../../atoms/ATextField";
import { AuthService } from "../../../apis/auth";

function OLoginDialog() {
  const { auth, setAuth } = useAuth();
  const { openLoginDialog, openVerifOtpDialog } = auth;

  const setOpenLoginDialog = () => {
    setAuth({ ...auth, openLoginDialog: !openLoginDialog });
  };

  const setOpenVerifOtpDialog = (payload) => {
    setAuth({ ...auth, openVerifOtpDialog: !openVerifOtpDialog, payload });
  };

  const {
    handleChange, handleSubmit, values, errors, touched,
  } = useFormik({
    initialValues: {
      phone_number: "",
      country_code: "62",
    },
    validationSchema: Yup.object({
      phone_number: Yup.string()
        .required("Nomor hp tidak boleh kosong.")
        .matches(/[0-9]/, "Hanya menerima inputan berupa angka.")
        .min(9, "Minimum mengandung 9 karakter.")
        .max(13, "Maksimum mengandung 13 karakter."),
      country_code: Yup.string().required("Kode negara tidak boleh kosong."),
    }),
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  const mutation = useMutation((values) => AuthService.getOtp(values), {
    onMutate: () => {
      console.log("mutate get otp");
    },
    onSettled: async (data) => {
      if (data.meta.success) {
        setOpenVerifOtpDialog(values);
      }
    },
  });

  return (
    <ADialogBox
      content={{
        title: "Masuk",
        body: (
          <form onSubmit={handleSubmit} className="my-2">
            <div className="flex gap-3">
              <div className="w-1/5">
                <ATextField
                  isDisabled
                  type="text"
                  name="country_code"
                  label="Kode"
                  placeholder="Kode"
                  value={`+ ${values.country_code}`}
                />
              </div>

              <ATextField
                type="text"
                name="phone_number"
                placeholder="Masukkan nomor hp"
                label="Nomor hp"
                value={values.phone_number}
                handleChange={handleChange}
                error={!!(touched.phone_number && errors.phone_number)}
                helperText={errors.phone_number}
              />
            </div>
            <div className="mt-4">
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                style={{ width: "100%" }}
                loading={mutation.isLoading}
              >
                Masuk
              </LoadingButton>
            </div>
          </form>
        ),
      }}
      isOpen={openLoginDialog}
      setIsOpen={setOpenLoginDialog}
    />
  );
}

export default OLoginDialog;
