import _ from "lodash";
import qs from "qs";
import AxiosInstance from "../../config/AxiosInstance";

export class ProductService {
  static GET_PRODUCT_ROUTE = "/products";

  static GET_SEARCH_ROUTE = "/search";

  static buildFilter(filter) {
    const clonedFilter = {
      ...filter,
    };
    return {
      ...clonedFilter,
    };
  }

  static getProducts = async ({
    pageParam,
    category_id,
    size,
    keyword,
    outlet_id,
    final_price__gte,
    final_price__lte,
    province_code,
    sort,
  }) => {
    try {
      const params = _.pickBy({
        page: pageParam,
        category_id,
        size,
        keyword,
        final_price__gte,
        final_price__lte,
        province_code,
        sort,
        include: ["productImages", "outlet"],
      },
      (val) => val,
      );

      const headers = _.pickBy({
        outlet_id,
      },
      (val) => val,
      );

      const response = await AxiosInstance().get(`${this.GET_PRODUCT_ROUTE}`, {
        params,
        headers,
        paramsSerializer: (params) => qs.stringify(params, {
          indices: true,
        }),
      });
      const {
        data,
      } = response;

      return {
        results: data.data,
        nextPage: pageParam + 1,
      };
    } catch (error) {
      return error.response.data;
    }
  };

  static getProductRecommendations = async () => {
    try {
      const params = _.pickBy({
        recommended: true,
        size: 20,
        include: ["productImages", "outlet"],
      },
      (val) => val,
      );

      const response = await AxiosInstance().get(`${this.GET_PRODUCT_ROUTE}`, {
        params,
      });
      const {
        data,
      } = response;
      return data;
    } catch (error) {
      return error.response.data;
    }
  };

  static getProductPopulars = async () => {
    try {
      const params = _.pickBy({
        sort: "-sold_count",
        size: 20,
        include: ["productImages", "outlet"],
      },
      (val) => val,
      );

      const response = await AxiosInstance().get(`${this.GET_PRODUCT_ROUTE}`, {
        params,
      });
      const {
        data,
      } = response;
      return data;
    } catch (error) {
      return error.response.data;
    }
  };

  static getProduct = async (product_id) => {
    const params = {
      include: [
        "productVariants.productVariantSelectedOptions",
        "productVariantTypes.productVariantOptions",
        "productImages",
        "outlet",
      ],
    };
    try {
      const response = await AxiosInstance().get(`${this.GET_PRODUCT_ROUTE}/${product_id}`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, {
          indices: true,
        }),
      });
      const {
        data,
      } = response;
      const product = data.data;
      return product;
    } catch (error) {
      return error.response.data;
    }
  };

  static getReviewByProduct = async ({
    pageParam,
    size,
    product_id,
    rating,
  }) => {
    try {
      const params = _.pickBy({
        page: pageParam,
        size,
        rating,
      },
      (val) => val,
      );

      const response = await AxiosInstance().get(`${this.GET_PRODUCT_ROUTE}/${product_id}/reviews`, {
        params,
      });
      const {
        data,
      } = response;
      return {
        results: data.data,
        nextPage: pageParam + 1,
      };
    } catch (error) {
      return error.response.data;
    }
  };

  static getReviewSummaries = async ({
    product_id,
  }) => {
    try {
      const response = await AxiosInstance().get(`${this.GET_PRODUCT_ROUTE}/${product_id}/review-summaries`);
      const {
        data,
      } = response;
      const product = data.data;
      return product;
    } catch (error) {
      return error.response.data;
    }
  };

  static getSearch = async ({
    size,
    keyword,
  }) => {
    try {
      const params = _.pickBy({
        size,
        keyword,
      },
      (val) => val,
      );

      const response = await AxiosInstance().get(`${this.GET_SEARCH_ROUTE}`, {
        params,
      });
      const {
        data,
      } = response;
      const {
        outlets,
      } = data.data;
      const {
        products,
      } = data.data;
      return {
        outlets,
        products,
      };
    } catch (error) {
      return error.response.data;
    }
  };
}
