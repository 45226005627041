import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GrClose } from "react-icons/gr";

function ADialogBox({ isOpen, setIsOpen, content, form, handleSubmit }) {
  return (
    <Dialog maxWidth="xs" fullWidth onClose={() => setIsOpen(false)} open={isOpen}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <p className="font-bold text-lg text-primary">{content.title}</p>
          <GrClose cursor="pointer" size={20} onClick={() => setIsOpen(false)} />
        </div>
      </DialogTitle>
      {form ? (
        <form onSubmit={handleSubmit}>
          <DialogContent dividers>{content.body}</DialogContent>
          {content.footer}
        </form>
      ) : (
        <>
          <DialogContent dividers>{content.body}</DialogContent>
          {content.footer}
        </>
      )}
    </Dialog>
  );
}

export default ADialogBox;
