import qs from "qs";
import AxiosInstance from "../../config/AxiosInstance";

export class CartService {
  static CART_ROUTE = "/carts";

  static addToCart = async (product_variant_id) => {
    const new_payload = {
      product_variant_id,
      quantity: 1,
    };
    try {
      const response = await AxiosInstance().post(`${this.CART_ROUTE}`, new_payload);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static getUserCart = async () => {
    const params = {
      include: ["productVariant.product", "productVariant.product.productImages"],
    };
    try {
      const response = await AxiosInstance().get(`${this.CART_ROUTE}`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, {
          indices: true,
        }),
      });
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static updateCart = async ({
    cart_id, product_variant_id, quantity, notes,
  }) => {
    const new_payload = {
      product_variant_id,
      quantity,
      notes,
    };
    try {
      const response = await AxiosInstance().put(`${this.CART_ROUTE}/${cart_id}`, new_payload);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static deleteCart = async ({ cart_id }) => {
    try {
      const response = await AxiosInstance().delete(`${this.CART_ROUTE}/${cart_id}`);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static getCartCounter = async () => {
    try {
      const response = await AxiosInstance().get(`${this.CART_ROUTE}/counter`);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static checkoutCart = async ({ payload }) => {
    try {
      const response = await AxiosInstance().post(`${this.CART_ROUTE}/checkout`, payload);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static getOutletCouriers = async ({ payload }) => {
    try {
      const response = await AxiosInstance().post(`${this.CART_ROUTE}/outlet-couriers`, payload);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      return err;
    }
  };
}
