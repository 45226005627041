import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Category = lazy(() => import("../../pages/category/Category"));
const CategoryDetail = lazy(() => import("../../pages/category/CategoryDetail"));

function CategoryRoute() {
  return (
    <Routes>
      <Route path="/" element={<Category />} />
      <Route path="/:id" element={<CategoryDetail />} />
    </Routes>
  );
}

export default CategoryRoute;
