import React from "react";
import { LoadingButton } from "@mui/lab";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import localStorageService from "../../../utils/localStorageService";
import { AuthService } from "../../../apis/auth";
import useAuth from "../../../helpers/useAuth";
import ADialogBox from "../../atoms/ADialogBox";

const renderButton = (buttonProps) => (
  <div className="flex gap-1">
    <p> Tidak Menerima Kode?</p>
    <button className="text-primary" {...buttonProps}>
      <h1
        className={`flex text-xs font-normal ${
          buttonProps.remainingTime !== 0 ? "cursor-not-allowed text-black" : "cursor-pointer"
        }`}
      >
        Kirim Ulang
        {buttonProps.remainingTime !== 0 && ` (${buttonProps.remainingTime}s)`}
      </h1>
    </button>
  </div>
);

const renderTime = () => React.Fragment;

function OVerifOtpDialog() {
  const { enqueueSnackbar } = useSnackbar();
  const { auth, setAuth } = useAuth();
  const { openVerifOtpDialog, payload } = auth;

  const setOpenVerifOtpDialog = () => {
    setAuth({ ...auth, openVerifOtpDialog: !openVerifOtpDialog });
  };

  const {
    setValues, handleSubmit, values, errors, touched, handleReset,
  } = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Otp tidak boleh kosong.").min(6, "Minimum mengandung 6 karakter."),
    }),
    onSubmit: (values) => {
      mutationSendOtp.mutate({ payload, otp: values.otp });
    },
  });

  const mutation = useMutation(() => AuthService.getOtp({ ...payload }), {
    onMutate: () => {
      console.log("mutate resend otp");
    },
  });

  const mutationSendOtp = useMutation((values) => AuthService.verifyOtp(values), {
    onMutate: () => {
      console.log("mutate verify otp");
    },
    onSettled: async (data) => {
      if (data?.data?.user_role === "new") {
        setAuth({
          ...auth,
          openVerifOtpDialog: false,
          openLoginDialog: false,
          openRegisterDialog: true,
          payload: { ...payload, otp: values.otp },
        });
      } else if (data.data) {
        const token = data?.data?.access_token;
        const name = data?.data?.nama_lengkap;
        localStorageService.setToken(token);
        localStorageService.setProfileName(name);
        setAuth({
          ...auth,
          accessToken: token,
          openVerifOtpDialog: false,
          openLoginDialog: false,
        });
      } else {
        enqueueSnackbar("Invalid Otp!", {
          variant: "error",
          autoHideDuration: 1500,
        });
        handleReset({});
      }
    },
  });

  return (
    <ADialogBox
      content={{
        title: "Verifikasi",
        body: (
          <form onSubmit={handleSubmit}>
            <p className="text-sm font-bold">Masukkan Kode Verifikasi</p>
            <p className="text-xs mt-0.5">Kode verifikasi telah dikirimkan ke nomor anda</p>
            <div className="my-2">
              <OTPInput
                value={values.otp}
                onChange={(e) => setValues({ ...values, otp: e })}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure={false}
                className="grid grid-cols-6 gap-4"
                inputClassName="border-b border-softBlack text-2xl focus:outline-none focus:ring-0"
                inputStyles={{ height: "50px", width: "100%", marginRight: 0 }}
              />
              {touched.otp && errors.otp && <p className="text-xs mt-2 text-error">{errors.otp}</p>}
              <div className="text-xs mt-2">
                <ResendOTP
                  maxTime={60}
                  onResendClick={() => mutation.mutate()}
                  renderButton={renderButton}
                  renderTime={renderTime}
                />
              </div>
            </div>
            <div className="mt-3 mb-1">
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                style={{ width: "100%" }}
                loading={mutationSendOtp.isLoading}
              >
                Kirim
              </LoadingButton>
            </div>
          </form>
        ),
      }}
      isOpen={openVerifOtpDialog}
      setIsOpen={setOpenVerifOtpDialog}
    />
  );
}

export default OVerifOtpDialog;
