import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Transaction = lazy(() => import("../../pages/transaction/Transaction"));
const TransactionDetail = lazy(() => import("../../pages/transaction/TransactionDetail"));

function TransactionRoute() {
  return (
    <Routes>
      <Route path="/" element={<Transaction />} />
      <Route path="/:id" element={<TransactionDetail />} />
    </Routes>
  );
}

export default TransactionRoute;
