import axios from "axios";
import LocalStorageService from "../utils/localStorageService";

const localStorageService = LocalStorageService.getService();

const AxiosInstance = () => axios.create({
  baseURL: process.env.REACT_APP_KADA_API_BASE_URL,
  headers: {
    authorization: `Bearer ${localStorageService.getAccessToken()}`,
    "Content-Type": "application/json",
  },
});

export default AxiosInstance;
