const localStorageService = (function () {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  function setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj);
    localStorage.setItem("refresh_token", tokenObj);
  }

  function setProfileName(name) {
    localStorage.setItem("profile_name", name);
  }

  function setCheckoutCart(cart) {
    localStorage.setItem("checkout_cart", JSON.stringify(cart));
  }

  function setSelectedAddress(address) {
    localStorage.setItem("selected_address", JSON.stringify(address));
  }

  function setSelectedPaymentmethod(payment_method) {
    localStorage.setItem("selected_payment_method", JSON.stringify(payment_method));
  }

  function setSelectedCourier(courier) {
    localStorage.setItem("selected_courier", JSON.stringify(courier));
  }

  function setSelectedCourierService(courier_service) {
    localStorage.setItem("selected_courier_service", JSON.stringify(courier_service));
  }

  function getAccessToken() {
    return localStorage.getItem("access_token");
  }

  function getProfileName() {
    return localStorage.getItem("profile_name");
  }

  function getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  function getCheckoutCart() {
    return localStorage.getItem("checkout_cart");
  }

  function getSelectedAddress() {
    return localStorage.getItem("selected_address");
  }

  function getSelectedPaymentMethod() {
    return localStorage.getItem("selected_payment_method");
  }

  function getSelectedCourier() {
    return localStorage.getItem("selected_courier");
  }

  function getSelectedCourierService() {
    return localStorage.getItem("selected_courier_service");
  }

  function removeSelectedAddress() {
    return localStorage.removeItem("selected_address");
  }

  function removeSelectedPaymentMethod() {
    return localStorage.removeItem("selected_payment_method");
  }

  function removeSelectedCourier() {
    return localStorage.removeItem("selected_courier");
  }

  function removeSelectedCourierService() {
    return localStorage.removeItem("selected_courier_service");
  }

  function clearLocalStorage() {
    localStorage.clear();
  }
  return {
    getService,
    setToken,
    setProfileName,
    getAccessToken,
    getProfileName,
    getRefreshToken,
    setCheckoutCart,
    getCheckoutCart,
    setSelectedAddress,
    getSelectedAddress,
    setSelectedPaymentmethod,
    getSelectedPaymentMethod,
    setSelectedCourier,
    getSelectedCourier,
    getSelectedCourierService,
    setSelectedCourierService,
    removeSelectedAddress,
    removeSelectedCourier,
    removeSelectedPaymentMethod,
    removeSelectedCourierService,
    clearLocalStorage,
  };
}());

export default localStorageService;
