import moment from "moment";
import AxiosInstance from "../../config/AxiosInstance";
import { subsPhoneNumber } from "../../utils/common";

export class AuthService {
  static AUTH_GET_OTP_ROUTE = "/customers/get-otp";

  static AUTH_VERIFY_OTP_ROUTE = "/customers/verify-otp";

  static AUTH_REGISTER_ROUTE = "/customers/register";

  static getOtp = async (payload) => {
    try {
      const phone_number = subsPhoneNumber(payload.phone_number);
      const new_payload = {
        ...payload,
        phone_number,
      };

      const response = await AxiosInstance().post(`${this.AUTH_GET_OTP_ROUTE}`, new_payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error.response.data;
    }
  };

  static verifyOtp = async (payload) => {
    const phone_number = subsPhoneNumber(payload.payload.phone_number);
    const new_payload = {
      ...payload,
      ...payload.payload,
      phone_number,
    };
    delete new_payload.payload;

    try {
      const response = await AxiosInstance().post(`${this.AUTH_VERIFY_OTP_ROUTE}`, new_payload);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };

  static register = async (payload) => {
    const birth_date = moment(payload.birth_date).format("YYYY-MM-DD");
    const new_payload = {
      ...payload,
      birth_date,
    };

    try {
      const response = await AxiosInstance().post(`${this.AUTH_REGISTER_ROUTE}`, new_payload);
      const { data } = response;
      return data;
    } catch (error) {
      const err = error.response.data;
      console.log(err);
      return err;
    }
  };
}
