import { DatePicker } from "@mui/lab";
import ATextField from "./ATextField";

function ADatePicker({ value, label, onChange }) {
  return (
    <DatePicker
      maxDate={new Date()}
      openTo="year"
      views={["year", "month", "day"]}
      label={label}
      value={value}
      onChange={onChange}
      className="w-full"
      renderInput={(params) => <ATextField {...params} error={false} helperText={null} className="w-full" />}
    />
  );
}

export default ADatePicker;
