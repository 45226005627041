import { Divider, IconButton, InputBase } from "@mui/material";

import { IoIosSearch } from "react-icons/io";

function ASearchbar({ children, onParentFocus, onParentBlur, placeholder, ...props }) {
  return (
    <div onFocus={onParentFocus} onBlur={onParentBlur}>
      <div className="relative flex h-full bg-white rounded-md shadow border ">
        <InputBase
          {...props}
          sx={{ pl: 1.5, flex: 1, fontSize: "0.8rem" }}
          placeholder={placeholder}
          inputProps={{ "aria-label": `${placeholder}` }}
        />
        <Divider sx={{ height: "100%" }} orientation="vertical" />
        <IconButton type="button" aria-label="search">
          <IoIosSearch className="fill-primary" />
        </IconButton>
      </div>
      {children}
    </div>
  );
}

export default ASearchbar;
