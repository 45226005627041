/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MMenubar from "./components/molecules/MMenubar";
import OLoginDialog from "./components/organisms/authentication/OLoginDialog";
import OVertifOtpDialog from "./components/organisms/authentication/OVerifOtpDialog";
import Wrapper from "./components/organisms/Wrapper";
import { checkToken } from "./helpers/RequireAuth";
import useAuth from "./helpers/useAuth";
import MainRoute from "./routes/Route";
import localStorageService from "./utils/localStorageService";
import { ALoading } from "./components/atoms/ALoading";
import ORegisterDialog from "./components/organisms/authentication/ORegisterDialog";

function App() {
  const { auth, setAuth } = useAuth();
  const { openLoginDialog, openVerifOtpDialog, openRegisterDialog } = auth;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorageService.getAccessToken();
    const decoded = checkToken(token);

    if (token && decoded) {
      localStorageService.setToken(token);
      setAuth({
        ...auth,
        accessToken: token,
      });
    } else {
      localStorageService.clearLocalStorage();
      setAuth({ ...auth, accessToken: "" });
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center pt-28">
        <ALoading />
      </div>
    );
  }

  return (
    <Wrapper>
      <main>
        {openLoginDialog && <OLoginDialog />}
        {openVerifOtpDialog && <OVertifOtpDialog />}
        {openRegisterDialog && <ORegisterDialog />}
        <section>
          <MMenubar />
        </section>
        <section>
          <MainRoute />
        </section>
      </main>
    </Wrapper>
  );
}

export default App;
